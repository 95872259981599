<template>
  <div class="workbenchHomePage">
    <div class="homePage-content">
      <!-- 通知栏 -->
      <!-- <div class="notice">
        <div class="notice-l">
          <img src="./img/tongzhi.png" alt="" />
          <span>通知</span>
          <div class="line"></div>
        </div>
        <div class="notice-c">
          <van-swipe
            :show-indicators="false"
            vertical
            class="my-swipe"
            :autoplay="3000"
          >
            <van-swipe-item v-for="(item, index) in noticeList" :key="index">{{
              item.label
            }}</van-swipe-item>
          </van-swipe>
        </div>
        <div class="notice-r">2023-1-15</div>
      </div> -->
      <!-- 个人信息 -->
      <div class="info">
        <div class="name">
          <span> Hi，{{ account }} </span>
          <img @click="handelScanQrCodes" src="./img/sanma.png" alt="" />
        </div>
        <div class="info-data">
          <div class="content">
            <div class="label-number">
              {{ PerformanceTotals.statusNormal || 0 }}
            </div>
            <div class="label-text">设备总数</div>
          </div>
          <div class="content">
            <div class="label-number">
              {{ PerformanceTotals.statusOffline || 0 }}
            </div>
            <div class="label-text">离线设备数</div>
          </div>
          <div class="content">
            <div class="label-number">
              {{ PerformanceTotals.statusAbnormal || 0 }}
            </div>
            <div class="label-text">设备告警数</div>
          </div>
          <div class="content">
            <div class="label-number">
              {{ PerformanceTotals.workInfoNum || 0 }}
            </div>
            <div class="label-text">本月工单数</div>
          </div>
          <div class="content" v-if="userTypesList.includes(101)">
            <div class="label-number">
              {{ PerformanceTotals.patrolNum || 0 }}
            </div>
            <div class="label-text">本月巡检数</div>
          </div>
          <div class="content" v-if="userTypesList.includes(100)">
            <div class="label-number">
              {{ PerformanceTotals.maintenanceNum || 0 }}
            </div>
            <div class="label-text">本月维保数</div>
          </div>
        </div>
      </div>
      <!-- 我的应用 -->
      <div class="my-apply">
        <div class="title">我的应用</div>
        <div class="apply-list">
          <div
            class="item"
            v-for="(item, index) in applyList"
            :key="index"
            v-show="!item.userType || userTypesList.includes(item.userType)"
            @click="handelRouter(item.path)"
          >
            <div class="apply-name">{{ item.name }}</div>
            <div class="apply-icon">
              <img :src="item.icon" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!--代办任务 -->
      <div class="task" v-if="userTypesList.includes(101) || userTypesList.includes(100)">
        <div class="title">待办任务</div>
        <div class="task-content">
          <!-- <div class="task-item" @click="handelRouter('maintenanceWorkOrder')">
            <div class="item-l">维修工单</div>
            <div class="item-r">
              <div class="residue">
                剩余：{{ PerformanceDetails.workInfoRemainNum || 0 }}
              </div>
              <div class="finish">
                完成：{{ PerformanceDetails.workInfoFinishNum || 0 }}
              </div>
            </div>
          </div> -->
          <div v-if="userTypesList.includes(101)" class="task-item" @click="handelRouter('inspectionTasks')">
            <div class="item-l">巡检任务</div>
            <div class="item-r">
              <div class="residue">
                剩余：{{ PerformanceDetails.patrolRemainNum || 0 }}
              </div>
              <div class="finish">
                完成：{{ PerformanceDetails.patrolFinishNum || 0 }}
              </div>
            </div>
          </div>
          <div v-if="userTypesList.includes(100)" class="task-item" @click="handelRouter('deviceMaintenance')">
            <div class="item-l">维保任务</div>
            <div class="item-r">
              <div class="residue">
                剩余：{{ PerformanceDetails.maintenanceRemainNum || 0 }}
              </div>
              <div class="finish">
                完成：{{ PerformanceDetails.maintenanceFinishNum || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <tabBar></tabBar> -->
  </div>
</template>

<script>
import Vue from "vue";
import * as dd from "dingtalk-jsapi";
import { Swipe, SwipeItem } from "vant";
Vue.use(Swipe);
Vue.use(SwipeItem);
import { getPerformanceDetails, homePageTotal, getUserTypeUrl } from "./api.js";
import tabBar from "./components/tabBar.vue";
import { scanQrcode } from "@/utils/utils.js";
export default {
  // components: { tabBar },
  name: "workbenchHomePage",
  props: {},
  data() {
    return {
      PerformanceDetails: {}, //待办任务数据
      PerformanceTotals: {}, //设备or工单总数
      workOrderMonthTotal: "", //本月工单数
      applyList: [
        // {
        //   name: "服务工单",
        //   icon: require("./img/workOrder.png"),
        //   path: "maintenanceWorkOrder",
        // },
        {
          name: "设备巡检",
          icon: require("./img/polling.png"),
          path: "inspectionTasks",
          userType: 101
        },
        {
          name: "设备维保",
          icon: require("./img/maintenance.png"),
          path: "deviceMaintenance",
          userType: 100
        },
        {
          name: "上报工单",
          icon: require("./img/report-workOrder.png"),
          path: "maintenanceReport",
        },
      ],
      noticeList: [
        {
          label: "设备巡检提醒",
        },
        {
          label: "设备巡检提醒设备巡检提醒",
        },
        {
          label:
            "设备巡检提醒设备巡检提醒设备巡检提醒设备巡检提醒设备巡检提醒设备巡检提醒",
        },
      ],
      userTypesList: [],
    };
  },
  created() {
    this.getUserTypeList();
    this.getPerformanceDetails();
    this.getPerformanceTotal();
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    account() {
      return this.$store.state.account;
    },
    mobile() {
      return this.$store.state.mobile;
    },
    target() {
      return this.$store.state.target;
    },
  },
  mounted() {
    window.scanCallback = this.scanCallback;
  },
  methods: {
    getUserTypeList() {
      this.$axios.get(getUserTypeUrl, { params: { mobile: this.mobile } }).then(res => {
        if (res && res.code === 200) {
          res.data = res.data || {};
          this.userTypesList = Object.keys(res.data).map(k => +k);
          console.log('this.userTypesList => ', this.userTypesList);
        }
      });
    },
    scanCallback(str) {
      console.log("扫码内容" + str);
      this.$router.push({
        name: "equipmentInfo",
        query: {
          deviceId: str,
        },
      });
    },
    //扫码
    handelScanQrCodes() {
      if (this.target == "yshgj") {
        scanQrcode();
      } else {
        var userAgentStr = window.navigator.userAgent;
        var patt = /DingTalk/g;
        if (patt.test(userAgentStr)) {
          dd.ready(() => {
            dd.scan({
              type: "qr",
            })
              .then((res) => {
                this.$router.push({
                  name: "equipmentInfo",
                  query: {
                    deviceId: res.text,
                  },
                });
              })
              .catch();
          });
        } else {
          if (window.location.origin.indexOf("https") != -1) {
            this.$router.push({
              name: "scanQrCodes",
              query: {
                url: "equipmentInfo",
              },
            });
          } else {
            this.$router.push({
              name: "equipmentInfo",
              query: {
                deviceId: "8.-2.-S231.SHSB1",
              },
            });
          }
        }
      }
    },
    //跳转
    handelRouter(name) {
      this.$router.push({
        name,
      });
    },
    //获取设备or工单总数
    getPerformanceTotal() {
      this.$axios
        .get(homePageTotal, {
          params: {
            phone: this.mobile,
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.PerformanceTotals = res.data;
          }
        });
    },
    //获取业绩详情
    getPerformanceDetails() {
      this.$axios
        .get(getPerformanceDetails, {
          params: {
            phone: this.mobile,
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.PerformanceDetails = res.data;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.workbenchHomePage {
  width: 100%;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 46px 0 160px;
  .homePage-content {
    width: 100%;
    padding: 26px 30px;
    box-sizing: border-box;
    .title {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
    }
    .task {
      margin-top: 36px;
      .task-content {
        margin-top: 32px;
        .task-item {
          min-height: 174px;
          background: #ffffff;
          border-radius: 16px;
          box-sizing: border-box;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 38px 70px 38px 42px;
          .item-l {
            display: flex;
            align-items: center;
            line-height: 40px;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            &::before {
              content: "";
              display: inline-block;
              margin-right: 10px;
              width: 8px;
              height: 8px;
              background: #fe4416;
              border-radius: 50%;
            }
          }
          .item-r {
            .residue {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff9b55;
              line-height: 40px;
              margin-bottom: 18px;
            }
            .finish {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
              line-height: 40px;
            }
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
    .my-apply {
      width: 100%;
      margin-top: 36px;
      box-sizing: border-box;
      .apply-list {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // gap: 20px 0px;
        box-sizing: border-box;
        .item {
          width: calc(100% / 2 - 11px);
          display: flex;
          justify-content: space-between;
          padding: 34px 32px 26px 36px;
          box-sizing: border-box;
          background: #ffffff;
          border-radius: 16px;
          margin-bottom: 20px;
          .apply-name {
            // white-space: nowrap;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
            box-sizing: border-box;
          }
          .apply-icon {
            width: 70px;
            height: 70px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      margin-top: 20px;
      height: 348px;
      overflow: hidden;
      background-image: url("./img/bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 42px 26px 24px 36px;
      .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        span {
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 40px;
        }
        img {
          width: 34px;
          height: 32px;
        }
      }
      .info-data {
        flex: 1;
        box-sizing: border-box;
        padding-left: 50px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 58px;
        .content {
          width: calc(100% / 3);
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:nth-child(-n + 3) {
            margin-bottom: 42px;
          }
          .label-number {
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 44px;
            margin-bottom: 10px;
          }
          .label-text {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 34px;
          }
        }
      }
    }
    .notice {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 26px 0 36px;
      width: 100%;
      height: 74px;
      background: rgba(254, 68, 22, 0.05);
      border-radius: 10px;
      .notice-l {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        flex-shrink: 0;
        img {
          width: 30px;
          height: 24px;
          margin-right: 10px;
        }
        span {
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.5);
          margin-right: 26px;
        }
        .line {
          width: 2px;
          height: 36px;
          background-color: #e3e3e3;
          margin-right: 28px;
        }
      }
      .notice-c {
        height: 100%;
        flex: 1;
        max-width: calc(100% - 122px - 126px - 28px);
        box-sizing: border-box;
        .my-swipe {
          height: 100%;
          width: 100%;
          overflow: hidden;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 74px;
          ::v-deep .van-swipe-item {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .notice-r {
        flex-shrink: 0;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>
