//根据设备sn查询工单列表
const getWorkListByDeviceSn = `/gateway/blade-system/workInfoApp/getWorkListByDeviceSn`;
//根据ID获取详情
const getWorkInfoById = `/gateway/blade-system/workInfoApp/getWorkInfoById`;
//获取业绩总览
const getPerformanceOverview = `/gateway/blade-system/workInfoApp/getPerformanceOverview`;
//业绩详情
const getPerformanceDetails = `/gateway/blade-system/desktop/homePageRemain`;
//获取总数
const homePageTotal = `/gateway/blade-system/desktop/homePageTotal`;
//获取工单统计数据
const getWorkStatistics = `/gateway/blade-system/workInfoDesktop/getWorkStatistics`;
//巡检月度完成量
const getPatrolMonthFinishNum = `/gateway/blade-system/patrolTask/monthFinishNum`;
//巡检时间偏好
const getPatrolTimeLike = `/gateway/blade-system/patrolTask/timeLike`;
//维保月度完成量
const getMaintenanceMonthFinishNum = `/gateway/blade-system/maintenance/monthFinishNum`;
//类型分布
const getMaintenanceTypeNum = `/gateway/blade-system/maintenance/typeNum`;
//维保记录
const getMaintenanceListByDeviceSn = `/gateway/blade-system/maintenance/getMaintenanceListByDeviceSn`;
//根据spaceId获取bimroad的id
const getBimRoadIdUrl = `/gateway/blade-system/workInfoApp/getBimRoadId`;
//根据ID获取详情
const getDeviceWorkInfo = `/gateway/blade-system/deviceWorkInfo/getWorkInfoById`;

//获取移动端登录用户类型
const getUserTypeUrl = `/gateway/blade-system/repairInfo/getUserType`;

export {
  getBimRoadIdUrl,
  getDeviceWorkInfo,
  getWorkListByDeviceSn,
  getWorkInfoById,
  getPerformanceOverview,
  getPerformanceDetails,
  getWorkStatistics,
  homePageTotal,
  getPatrolMonthFinishNum,
  getPatrolTimeLike,
  getMaintenanceMonthFinishNum,
  getMaintenanceTypeNum,
  getMaintenanceListByDeviceSn,
  getUserTypeUrl,
};
